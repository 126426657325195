import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PriceListPage = () => (
  <Layout>
    <SEO title="Yams Studio: Microsite Price List" />
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 m-5">
        <p className="text-xl md:text-3xl leading-none">
          You’re probably curious about what the costs are for booking a
          microsite with us.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          Don’t worry, we’ve got you covered.
        </p>
      </div>
      <div className="w-full md:w-1/3 m-5">
        <h2 className="text-base md:text-lg leading-tight uppercase">
          Price List
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          Bring your own design: $800 USD.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          If you’re a designer, you’re probably keen to design your own website.
          That’s cool with us! We may make a few tiny adjustments to fit our
          workflow, but we’re happy work with your design. Keep in mind this
          price is for a single-page microsite only.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Single-page microsite: $1,000 USD.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          You get a single-page website designed and built by us. No
          cookie-cutter designs here.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Two-page microsite: $1,400 USD.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          You get a two-page website designed and built by us. This is for those
          looking to build a portfolio or blog and would like to add a detail
          page for their work.
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          The Barter System
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          We are a big fans of the barter system. We’re always open to
          exchanging goods and services. Money isn’t the only thing that holds
          value &mdash; let’s chat.
        </p>
      </div>
    </div>
  </Layout>
)

export default PriceListPage
